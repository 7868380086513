<template >
  <div class="BahnR pt-6">
    <v-snackbar v-model="showsnackbar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <Login
      v-if="winStep == 0"
      v-bind:pageData="pageData"
      @stepper="winStepper"
    />
    <LoginOtp
      v-if="winStep == 1"
      v-bind:pageData="pageData"
      @stepper="winStepper"
      v-bind:lastPage="lastPage"
    />
  </div>
</template>
<script>
import Login from "./loginForm";
import LoginOtp from "./loginOtp";

export default {
  components: {
    Login,
    LoginOtp,
  },
  data() {
    return {
      showsnackbar: false,
      timeout: 5000,
      msg: null,
      winStep: 0,
      phoneno: null,
      pageData: {},
      lastPage: null,
    };
  },
  beforeMount() {
    if (this.$route.params.lastPage)
      this.lastPage = this.$route.params.lastPage;
  },
  methods: {
    winStepper(window_data) {
      this.winStep = window_data.winStep;
      this.pageData.phoneno = window_data.phoneno;
      if (window_data.response)
        this.pageData.pageResponse = window_data.response;
    },
  },
};
</script>